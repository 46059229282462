import validate from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/middleware/auth.global.ts";
import manifest_45route_45rule from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  creche: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/middleware/creche.ts"),
  guest: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/middleware/guest.ts"),
  organization: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/middleware/organization.ts"),
  tablet: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/middleware/tablet.ts")
}