export default {
    computed: {
        inOrganizationLayout () {
            return this.$route.meta.layout === 'organization'
        },
        inTabletLayout () {
            return this.$route.meta.layout === 'tablet'
        },

        layoutPrefix () {
            if (this.inOrganizationLayout) {
                return '/organization'
            }
            if (this.inTabletLayout) {
                return '/tablet'
            }
            return ''
        }
    }
}
