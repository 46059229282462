import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
    actions: {
        clearAll () {
            /**
             * TODO Implement reset of all stores
             */
        }
    }
})
