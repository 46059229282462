import { useAuthStore } from '~/stores/auth'
import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware(async (to) => {
    const auth = useAuthStore()

    if (auth.authenticated && !auth.user) {
        if (auth.token) {
            auth.setToken(auth.token)
        }
        await auth.fetchUser()
    }

    if ((!auth.authenticated || !auth.user) && to.meta.layout !== 'auth') {
        return navigateTo('/auth/login')
    }
})
