import { mapState } from 'pinia'
import { useAuthStore } from '~/stores/auth'

export default {
    computed: {
        ...mapState(useAuthStore, {
            $user: 'user',
            $creche: 'currentCreche',
            $organization: 'organization'
        })
    }
}
