
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexdBKU9QDvpYoRlELQykT50aTCS1yPbyAqqbZ6EaTOZPYMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/index.vue?macro=true";
import { default as login0lf4HBc3LdEVfWDfd393VrUUQ7zGg7xnCGumyVCSu0UMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/login.vue?macro=true";
import { default as logoutP65A62u0TH7lhd2Wn3jJeKoaMjeGaxULms7YF7UIPBEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/logout.vue?macro=true";
import { default as indexB9sz7G1I1ztdFPjnwUDcDDcE64DC1f85TlDUajSgPSEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/index.vue?macro=true";
import { default as indexKS54B4NNEQ0S4vN5u2_7VMwZktqWdjGkdYr5Awu_YX0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/index.vue?macro=true";
import { default as indexn28WmqGoK53lrA0dKwXNJ_456uihQ0_Ir5LJBxAQtS4GwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/index.vue?macro=true";
import { default as indexY_N2UlBsqUNo6eQm9xSJZ6wsKd5Z6B9QstxOOqXHP7kMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/index.vue?macro=true";
import { default as _91uuid_93n2YQ6X_45UjcIzFcJf9bo3MDwr_45_45AxZdLZ0STk7Zwp3lwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/[uuid].vue?macro=true";
import { default as createkVYxU0xhqNk7et4klXnumXhDlq_m5nKezznP_r75V54Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/create.vue?macro=true";
import { default as babiesA47uMvPH7r7a50BbZgwhbfv739wtcLAlK7E8xqvawMoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/babies.vue?macro=true";
import { default as indexmuHF350jLsgSIPBJ3rKokx9HCjmXgFBb5d0Cvhg0_k4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/index.vue?macro=true";
import { default as createCQekjUnrqeJ9W_45eHcFPQn8mYnf5pAxyOhbbQITiUtCUMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/create.vue?macro=true";
import { default as indexic3wVNUK2QbXvO2kIqnCdtkec0KNjztFQIeifc5CA0MMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Demands/index.vue?macro=true";
import { default as _91uuid_93xVuu7JG8ySyQnl_rA8Pv_vp6MaanlgvEO1ZY529bThgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/[uuid].vue?macro=true";
import { default as codaM0TTaG6qzEMYayD7ZJNwhJmiLa2phprSXD3kdLADvoIMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/coda.vue?macro=true";
import { default as _91id_93Y93EkvuOCyfGRtkcvi43XQAr0Y_xo66QZSlJgkDUhosMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/[id].vue?macro=true";
import { default as indexjJdQxuAh82iyzq5buPgcIM0Tr0ywIr04qjYc7WKaesYMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/index.vue?macro=true";
import { default as onecdqVsYU0Xoc3QzPa4wOuO_1170kBTP4icb7CiILogFQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/one.vue?macro=true";
import { default as financeiYiwwfVu55jeifLthEqjDhwPZcvYFuZ6kpOp3_45rjczQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/finance.vue?macro=true";
import { default as medicalSSzAMmSqhAiZs_0W4pz_JuM1di7kn_45cf9kQchm_yOMIMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/medical.vue?macro=true";
import { default as indexpMG65X3K_4529lPXwTGC4ZQMxmiIbzhk1vlwHyiPcGqz4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Activity/index.vue?macro=true";
import { default as indexyNukl55GEWxYo0HE94mIKv_45W_453r5QH2su0Z0QK4uRMwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/index.vue?macro=true";
import { default as indexLzNCRkSm8vlFvjjWshShiLYe6NLZVdnXdANXaBtvxCgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/index.vue?macro=true";
import { default as indexPsPGAKrew5gxbYDrRBmtZtXeRDFRcBdggow4Ub1HXgUMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/index.vue?macro=true";
import { default as calendar1VVeqkY8b3MC6qYJv3fRwkczZf3Q_jE8QbqoGTYjoDgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/calendar.vue?macro=true";
import { default as dayVZdxfaXLlO6_45Ox43dPoRSrFRTlATK8YCMmMtgP6MVHAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/day.vue?macro=true";
import { default as archived9kHwcERfE0qBKo4c7SV0X90ZBqfLQBNe8VRsMMsoBsoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/archived.vue?macro=true";
import { default as _91uuid_93OLSDffHmeJvoZ28_45ttCc0edzBDmhCeNC262TXC21z0EMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/[uuid].vue?macro=true";
import { default as createCtNFmCEoZYK3oNhhzdPZIRPkjnyro034crbuwmxPVSoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/create.vue?macro=true";
import { default as create6zmJAPBdKGtHmJ9f838Lw5eymf1gzIBvDLkU9xodQa0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/create.vue?macro=true";
import { default as crechegeLO48Z3HBDx_45OlFKr9Wq8qwuco_450cn_kXSZJmUyBkEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/creche.vue?macro=true";
import { default as weekQbfLtqoGk_45pAlfQwU6tdEw8z9nxMFJS2FUOTfDJWz0cMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/week.vue?macro=true";
import { default as index9byGxHD8MSwK_MNqVKIMKoKUtY5yCSsgIPft6R3ZK7gMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/index.vue?macro=true";
import { default as doctorsErSUo1XqJMZ39WjJC0TgFJ0ddE6XmS87y_45NnPwFgwyEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/doctors.vue?macro=true";
import { default as financetN4Avdnn9Z5BdPzE74X6u1rMP6E9HJCx7EHr_45SdHnIoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/finance.vue?macro=true";
import { default as index_U7QDV683JGjjaSfPC4VY7yW4cjw3nYOf8MOQ7t7eP0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/index.vue?macro=true";
import { default as forecastIWjGM_45ur2_XiSZQOl6pTcB6yJbN_h1e32hH0k_p_wT0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/forecast.vue?macro=true";
import { default as balances9Fx5vMFClex2lvwPz7fPj_zP48_459403kLrrH5TLSrPsMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/balances.vue?macro=true";
import { default as pro_45oneeZdVocdwWu_45Lax1SuPmTbWefzuGRIMu43DZVRJWl5A4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/pro-one.vue?macro=true";
import { default as schedule_WfU69yHMzzJOKmqoOSuunljBzNC_4551hEsYB0ea_45O10Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/schedule.vue?macro=true";
import { default as attendancesUcd8_45r3iX4xGmEC1WTC9dZpRVUpTGFtmND_45tATdEwewMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/attendances.vue?macro=true";
import { default as indexNmgg8HUL_45yjFDCMG9dzrSIP5J4o4D41VlTxL07VpFW8Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Scan/index.vue?macro=true";
import { default as indexNnnO_45YYktsbIbRcYHPjO9xJ1KnGnBlsrxH3Q_458jHs9wMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Availability/index.vue?macro=true";
import { default as indexZrU_45zXvKo59_gHlOpJO1goP_xGMHlQRpfe_45aH6LWeJQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/index.vue?macro=true";
import { default as documents8P9MAbdra2eD7V2aeFB_45d8ttQi05aCgFPRjikmyD3fQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/documents.vue?macro=true";
import { default as availabilitypF53RZhola6_OwjIKwM8rxnSExWPeNAfaLIANfyOVeQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/availability.vue?macro=true";
import { default as indexZZhx_453SSfM7DFyFDopeMuTGvPJId_UtstBdCjwvA0cEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Staff/index.vue?macro=true";
import { default as indexr4e_GheJ3SUx_jnEXMArazzbUSth8PY4ysiJ_4OTGwQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/index.vue?macro=true";
import { default as index_45KPmadwXULlADg5331NIpB29Hp1z5zPjgGZqC_45EWj40Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/index.vue?macro=true";
import { default as index5AlroRw1_ORV5PHaZLYzooDw1xReejerJ7EObbBFZ7cMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/index.vue?macro=true";
import { default as registrationsCDJWu9qas3wH1Uyy3d4nlwMIGQiUpA_45o_45fVU7806WqcMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/registrations.vue?macro=true";
import { default as indexeIM32W77LghlJus5dDxGdDrkZEBGrZCdbpkVRpV83woMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/index.vue?macro=true";
import { default as dayHq47EavkhoeeLajrWChfCb9eNMBG9fXYDaHxN_45v3Zg0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/day.vue?macro=true";
import { default as index_fCN5RiXTlhSq38ZC76ANOtgYpV_P4bsI3Q9cOK1KI4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Social/index.vue?macro=true";
import { default as createhlcf7oNAkusclrshXEvzQB4I0Qffp3uc4p_45r4LiWuqcMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/create.vue?macro=true";
import { default as indexol9M_45P_45BP6Y49CXd_zcQw6kSB4vp_XlBqZqE3uhiixkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/index.vue?macro=true";
import { default as mails1MpEcDZBweqxAPyR11dOAugHohZZ68_457b8MMjQCyJagMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/mails.vue?macro=true";
import { default as _91uuid_93W1EhuxhZLMcWeeH4OI7ehC_45oe6yilv5lYmaYcSOKdVEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/[uuid].vue?macro=true";
import { default as created5UOQCw4hcDPRMKvNYUssvcaW_1D4_45KWSKv84KNw67EMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/create.vue?macro=true";
import { default as _91uuid_93ZDJBYBvwFcwb5WMuyI3IHESuNq7Ntzt9bWT1fB3uWw0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/[uuid].vue?macro=true";
import { default as createj_mLI0syNhhe7Pjc9gIvkA9aVkjuLc_45eYPkjW0RrDdgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/create.vue?macro=true";
import { default as _91id_93rBGPFGXFQ8_45nteeOy50FzCbKmzf6_451g76hDaPzhtAoQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/[id].vue?macro=true";
import { default as finance9aAfBy0dT468H3vG82y_Q0aIrtTskym7UYGhbvUF7GAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/finance.vue?macro=true";
import { default as historyTh_45rV2oHI_45TZ7VZk_452A6pSW7JZ0armXwgZXulkTgqOYMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/history.vue?macro=true";
import { default as medical6dbq37OAn19cPn0H_45rkVoK3EpHCefpQfQSqyxZ_45GtBAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/medical.vue?macro=true";
import { default as parentsYTlIZ7Jt4eDGVKfs3uterTxkFuNWe2y77Z8c_45itXls4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/parents.vue?macro=true";
import { default as socialpSwVZt4DhVglfLaG7zSWsOZKirahyZQ_OHbw0oWKkKEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/social.vue?macro=true";
import { default as babiesMtKVidf5N1nWRBoemGXyCZPhZzt__45jH7ra4IHNLoVtQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/babies.vue?macro=true";
import { default as attendancesI9X_45bNb5sEY2dqn7Oc71Nj2JD2GDubGV9vbyi6ONDYkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/attendances.vue?macro=true";
import { default as indexRyOlp_45tJxGW5PDqRYZMo4qzUBWEwRjZ9NVO_BQFtOooMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Activity/index.vue?macro=true";
import { default as month_TMx7_juZ6giLdi_455lvlKxlZe9_VeDxVfI9NbBrUeB4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/month.vue?macro=true";
import { default as indexVtUZBSxDR7x5N4wPR_45zC5Ijxa8hoPzfVgcg_T_FWIXkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/index.vue?macro=true";
import { default as calendar7KPTsVgzrIdeVU1Ag1zrw4HGaGXw15mvcbV3u1CKjIwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/calendar.vue?macro=true";
import { default as account_f0BzrKZbvtWnLCgQ9uXcxeliAnFSCFWCz1zzqnridwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/account.vue?macro=true";
import { default as mailtemplatesG4KmiqoXhaVSaAT6c_cisokXFj70gElwSp8urdEzf0UMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/mailtemplates.vue?macro=true";
import { default as createsUuYzpoZAp6miw0efd3aL3rBLdga3m439w3s5ORxI6AMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/create.vue?macro=true";
import { default as classroom21iiEtQ6ppB5sskWyObvt41V607iCiEd0yH1CBHnhT8Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/classroom.vue?macro=true";
import { default as documentsUBkMf0M13iSgLB1X4_45AaTi_4513m_KCL1XHadARaTEzmAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/documents.vue?macro=true";
import { default as indexg0piucBNZvQ82lPoT4egHreWFm9g8hB2UhWXxOWIU2UMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/[uuid]/index.vue?macro=true";
import { default as invoicesg3wgC2QjkYVcsUKEksOwsQv225R_LG9SHdrLekQIytEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/invoices.vue?macro=true";
import { default as remunerations6IaEMdPgA5z_B8lM8Y9_3i2VRO_45JRO_45csrEu40BJy5EMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/remunerations.vue?macro=true";
import { default as activities3fnlwqt3ER24aZDhdSSPy32QSVEcPvXfbkspWas8g6sMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/activities.vue?macro=true";
import { default as index9ctyhJIMfH4iFjhTCYusYYdkQbWJ790ejd38AL5LmjQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/index.vue?macro=true";
import { default as indexrbCQBPL76h7gjiOf9uHgl0UVLqD3QjelM1zKG52HefMMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/index.vue?macro=true";
import { default as createShCC0QBUrSRxBGECUvr7ekSwhfBzu63xx_45r_xE4ktAgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/aes/create.vue?macro=true";
import { default as indexhAtbH_E5R4zoJlLKb2tf6DKZL2Vy65ne638e_450JFQdsMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Attendances/index.vue?macro=true";
import { default as _91uuid_93ldoPuJTLtGkz_GXPPLT5Z6tVnN876QO_45NMf_45oGJaZukMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/[uuid].vue?macro=true";
import { default as createyzJj1idBneNa8WN70SkJTKaWd5U_45Yt_IKIZ6euUA89gMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/create.vue?macro=true";
import { default as editCb0NufjXDh_455ACGQjzbHKfgsjTechKEp_h8SIPHZs9wMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/edit.vue?macro=true";
import { default as index5X0hdSKqLoiBpavbbvsKs0zYfgtPuNgwhniF9oROCrkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Social/index.vue?macro=true";
import { default as _91uuid_93MmHo6Y0qLYU7j0CnzWV3SBeBs4LGUDAcKd2o9E483FwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/[uuid].vue?macro=true";
import { default as createTNpLcyxSLmo47OT9ys6LoOUyEQPcLMOX09BLyF2AR1QMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/create.vue?macro=true";
import { default as fiscaldocumentsprSHgZ2of2s54lSpqa5JVZIXhpd_45B5gfgDQxGmL_ge8Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/fiscaldocuments.vue?macro=true";
import { default as registrationHoSR_45GUN9PUXs0_7MvTa6rBUcQSst1hCUQQthgCqxEwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/registration.vue?macro=true";
import { default as indexFWrZFZ1Eii0aOdUMqTlUcVgvCdR0DPZFIdsCt9G07TEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/index.vue?macro=true";
import { default as indexPWAgP_WhqlivgiPFpvZ27MkfkRAJOCehKdQuq8AC_45YwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/index.vue?macro=true";
import { default as codaAx6Fho3Xl_45s_45E9oHr_45oTfpcnOTvmv8JRAkdzO9_45oOZYMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/coda.vue?macro=true";
import { default as indexJmZhK6WQjeRKH60ZZsEzksr6iMKUsCeW_pnp78bJH9IMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/index.vue?macro=true";
import { default as index_IZnoWPXLPpJkpvcXhL3_BMjbE_45PA4uII3QMGHtLRMoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/index.vue?macro=true";
import { default as indexxTFfnPJrJX0h028vybZBPRSGHNkFLCVBb7Z8yHVF8wgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Calendar/index.vue?macro=true";
import { default as _91uuid_93VWZV2Y_Lexs8B6XZNb2JaTRzZDXBvneAMVHiZvaJvXUMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/[uuid].vue?macro=true";
import { default as indexSW_Y9F_45keW05nAAl1xdWYUuIHwe2rsG_nVW0l88ISc0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/index.vue?macro=true";
import { default as indexfjPA6kYSBkXgYGzLTYFGpesWE6YlG_AYNhchpvNd_45xEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/index.vue?macro=true";
import { default as _91uuid_93t9C0lr1h63mhe7mj6HcDG8Oy7bkd7HD27LFlXEBloeMMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/[uuid].vue?macro=true";
import { default as index1uDnRyEg10XjQndqeYXQI1xLThye3uk7OkReEO4ddVEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Settings/index.vue?macro=true";
import { default as _91uuid_93nvYz2cz1TGfdEpKNw61E8HITs_45Pvz_3adD_b71iBLHEMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/[uuid].vue?macro=true";
import { default as createe10GqoWkIL9WhOJJ0brn62eaCRT4IrbQKw5pZmtbzsoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/create.vue?macro=true";
import { default as _91uuid_93HYNuSIBPFJtCvx_45B22bs9yYA9WiI1gtM0oFF4WHeTjAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/[uuid].vue?macro=true";
import { default as createCjo1eSYWz9FvToZgRjSMq55WD_45BL8sBwLpkMiZszhesMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/create.vue?macro=true";
import { default as _91uuid_93PpfDTcuSn_45foy_4584IbjsylvU_4531iOexbiZs9p3CY_45BUMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/[uuid].vue?macro=true";
import { default as createtcBxMRssSGe6MztLIo8t4CUV_45nXYThrmSHNubdA_2mQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/create.vue?macro=true";
import { default as billingeBUpqifs4f7Fb_45xXLTcvBG0yq7rJvmiYcbUAomCzEbIMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/billing.vue?macro=true";
import { default as indexBPp6ILMyBYH4lVK85CYfe7ddd10GpDWha1BuS1glIEgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/aes/index.vue?macro=true";
import { default as indexbqNSysgeE2FcrCfLLmNZl5ADxWGzyl9Lhk_45qLkS7dL0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/index.vue?macro=true";
import { default as indexjJ7gDkNpZRwMaFTXxRMkSkvr5yPTbZJMM9PmOG2ewkoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Availability/index.vue?macro=true";
import { default as indexreqp_453q26cl6mxg3xUeGzkXjkHilBhm3fkyJ_2KhPNMMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/index.vue?macro=true";
import { default as attendancesCmrnq8ZO6gAGg4qshCQIladZhEJurXYGH3Kf_auYqqsMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/attendances.vue?macro=true";
import { default as pro_45one7il_45Z2ckokenu1wL1Ic2ATx4oid5s4TgdyeK8ZukmIsMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/pro-one.vue?macro=true";
import { default as _91uuid_93tgysuK_45jTnPIu8I647_45ykweRt_rA_45Z5343x1U2KPlKMMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/[uuid].vue?macro=true";
import { default as createTpXf3Ye8c1s4TTdI8S_45Bs1mwivI7ogVSNviOBM4FPvgMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/create.vue?macro=true";
import { default as remunerations_6Cwn5s5RPYHK9zvEgjZr_45jTF_Rpvid9h8A0MCPMlEkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/remunerations.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexdBKU9QDvpYoRlELQykT50aTCS1yPbyAqqbZ6EaTOZPYMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/index.vue")
  },
  {
    name: "Auth-login",
    path: "/Auth/login",
    meta: login0lf4HBc3LdEVfWDfd393VrUUQ7zGg7xnCGumyVCSu0UMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/login.vue")
  },
  {
    name: "Auth-logout",
    path: "/Auth/logout",
    meta: logoutP65A62u0TH7lhd2Wn3jJeKoaMjeGaxULms7YF7UIPBEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/logout.vue")
  },
  {
    name: "Staff",
    path: "/Staff",
    meta: indexB9sz7G1I1ztdFPjnwUDcDDcE64DC1f85TlDUajSgPSEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/index.vue")
  },
  {
    name: "Stats",
    path: "/Stats",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/index.vue")
  },
  {
    name: "Babies",
    path: "/Babies",
    meta: indexn28WmqGoK53lrA0dKwXNJ_456uihQ0_Ir5LJBxAQtS4GwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/index.vue")
  },
  {
    name: "Events",
    path: "/Events",
    meta: indexY_N2UlBsqUNo6eQm9xSJZ6wsKd5Z6B9QstxOOqXHP7kMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/index.vue")
  },
  {
    name: "Staff-uuid",
    path: "/Staff/:uuid()",
    meta: _91uuid_93n2YQ6X_45UjcIzFcJf9bo3MDwr_45_45AxZdLZ0STk7Zwp3lwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/[uuid].vue")
  },
  {
    name: "Staff-create",
    path: "/Staff/create",
    meta: createkVYxU0xhqNk7et4klXnumXhDlq_m5nKezznP_r75V54Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/create.vue")
  },
  {
    name: "Stats-babies",
    path: "/Stats/babies",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/babies.vue")
  },
  {
    name: "Tablet",
    path: "/Tablet",
    meta: indexmuHF350jLsgSIPBJ3rKokx9HCjmXgFBb5d0Cvhg0_k4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/index.vue")
  },
  {
    name: "Babies-create",
    path: "/Babies/create",
    meta: createCQekjUnrqeJ9W_45eHcFPQn8mYnf5pAxyOhbbQITiUtCUMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/create.vue")
  },
  {
    name: "Demands",
    path: "/Demands",
    meta: indexic3wVNUK2QbXvO2kIqnCdtkec0KNjztFQIeifc5CA0MMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Demands/index.vue")
  },
  {
    name: "Events-uuid",
    path: "/Events/:uuid()",
    meta: _91uuid_93xVuu7JG8ySyQnl_rA8Pv_vp6MaanlgvEO1ZY529bThgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/[uuid].vue")
  },
  {
    name: "Invoices-coda",
    path: "/Invoices/coda",
    meta: codaM0TTaG6qzEMYayD7ZJNwhJmiLa2phprSXD3kdLADvoIMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/coda.vue")
  },
  {
    name: "Messages-id",
    path: "/Messages/:id()",
    meta: _91id_93Y93EkvuOCyfGRtkcvi43XQAr0Y_xo66QZSlJgkDUhosMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/[id].vue")
  },
  {
    name: "Parents",
    path: "/Parents",
    meta: indexjJdQxuAh82iyzq5buPgcIM0Tr0ywIr04qjYc7WKaesYMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/index.vue")
  },
  {
    name: "Reporting-one",
    path: "/Reporting/one",
    meta: onecdqVsYU0Xoc3QzPa4wOuO_1170kBTP4icb7CiILogFQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/one.vue")
  },
  {
    name: "Stats-finance",
    path: "/Stats/finance",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/finance.vue")
  },
  {
    name: "Stats-medical",
    path: "/Stats/medical",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/medical.vue")
  },
  {
    name: "Activity",
    path: "/Activity",
    meta: indexpMG65X3K_4529lPXwTGC4ZQMxmiIbzhk1vlwHyiPcGqz4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Activity/index.vue")
  },
  {
    name: "Invoices",
    path: "/Invoices",
    meta: indexyNukl55GEWxYo0HE94mIKv_45W_453r5QH2su0Z0QK4uRMwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/index.vue")
  },
  {
    name: "Messages",
    path: "/Messages",
    meta: indexLzNCRkSm8vlFvjjWshShiLYe6NLZVdnXdANXaBtvxCgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/index.vue")
  },
  {
    name: "Payments",
    path: "/Payments",
    meta: indexPsPGAKrew5gxbYDrRBmtZtXeRDFRcBdggow4Ub1HXgUMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/index.vue")
  },
  {
    name: "Staff-calendar",
    path: "/Staff/calendar",
    meta: calendar1VVeqkY8b3MC6qYJv3fRwkczZf3Q_jE8QbqoGTYjoDgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/calendar.vue")
  },
  {
    name: "Attendances-day",
    path: "/Attendances/day",
    meta: dayVZdxfaXLlO6_45Ox43dPoRSrFRTlATK8YCMmMtgP6MVHAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/day.vue")
  },
  {
    name: "Babies-archived",
    path: "/Babies/archived",
    meta: archived9kHwcERfE0qBKo4c7SV0X90ZBqfLQBNe8VRsMMsoBsoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/archived.vue")
  },
  {
    name: "Invoices-uuid",
    path: "/Invoices/:uuid()",
    meta: _91uuid_93OLSDffHmeJvoZ28_45ttCc0edzBDmhCeNC262TXC21z0EMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/[uuid].vue")
  },
  {
    name: "Invoices-create",
    path: "/Invoices/create",
    meta: createCtNFmCEoZYK3oNhhzdPZIRPkjnyro034crbuwmxPVSoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/create.vue")
  },
  {
    name: "Messages-create",
    path: "/Messages/create",
    meta: create6zmJAPBdKGtHmJ9f838Lw5eymf1gzIBvDLkU9xodQa0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/create.vue")
  },
  {
    name: "Settings-creche",
    path: "/Settings/creche",
    meta: crechegeLO48Z3HBDx_45OlFKr9Wq8qwuco_450cn_kXSZJmUyBkEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/creche.vue")
  },
  {
    name: "Attendances-week",
    path: "/Attendances/week",
    meta: weekQbfLtqoGk_45pAlfQwU6tdEw8z9nxMFJS2FUOTfDJWz0cMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/week.vue")
  },
  {
    name: "Classrooms",
    path: "/Classrooms",
    meta: index9byGxHD8MSwK_MNqVKIMKoKUtY5yCSsgIPft6R3ZK7gMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/index.vue")
  },
  {
    name: "Settings-doctors",
    path: "/Settings/doctors",
    meta: doctorsErSUo1XqJMZ39WjJC0TgFJ0ddE6XmS87y_45NnPwFgwyEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/doctors.vue")
  },
  {
    name: "Settings-finance",
    path: "/Settings/finance",
    meta: financetN4Avdnn9Z5BdPzE74X6u1rMP6E9HJCx7EHr_45SdHnIoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/finance.vue")
  },
  {
    name: "Attendances",
    path: "/Attendances",
    meta: index_U7QDV683JGjjaSfPC4VY7yW4cjw3nYOf8MOQ7t7eP0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/index.vue")
  },
  {
    name: "Invoices-forecast",
    path: "/Invoices/forecast",
    meta: forecastIWjGM_45ur2_XiSZQOl6pTcB6yJbN_h1e32hH0k_p_wT0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/forecast.vue")
  },
  {
    name: "Payments-balances",
    path: "/Payments/balances",
    meta: balances9Fx5vMFClex2lvwPz7fPj_zP48_459403kLrrH5TLSrPsMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/balances.vue")
  },
  {
    name: "Reporting-pro-one",
    path: "/Reporting/pro-one",
    meta: pro_45oneeZdVocdwWu_45Lax1SuPmTbWefzuGRIMu43DZVRJWl5A4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/pro-one.vue")
  },
  {
    name: "Settings-schedule",
    path: "/Settings/schedule",
    meta: schedule_WfU69yHMzzJOKmqoOSuunljBzNC_4551hEsYB0ea_45O10Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/schedule.vue")
  },
  {
    name: "Stats-attendances",
    path: "/Stats/attendances",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/attendances.vue")
  },
  {
    name: "Tablet-Scan",
    path: "/Tablet/Scan",
    meta: indexNmgg8HUL_45yjFDCMG9dzrSIP5J4o4D41VlTxL07VpFW8Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Scan/index.vue")
  },
  {
    name: "Availability",
    path: "/Availability",
    meta: indexNnnO_45YYktsbIbRcYHPjO9xJ1KnGnBlsrxH3Q_458jHs9wMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Availability/index.vue")
  },
  {
    name: "Organization",
    path: "/Organization",
    meta: indexZrU_45zXvKo59_gHlOpJO1goP_xGMHlQRpfe_45aH6LWeJQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/index.vue")
  },
  {
    name: "Settings-documents",
    path: "/Settings/documents",
    meta: documents8P9MAbdra2eD7V2aeFB_45d8ttQi05aCgFPRjikmyD3fQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/documents.vue")
  },
  {
    name: "Stats-availability",
    path: "/Stats/availability",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/availability.vue")
  },
  {
    name: "Tablet-Staff",
    path: "/Tablet/Staff",
    meta: indexZZhx_453SSfM7DFyFDopeMuTGvPJId_UtstBdCjwvA0cEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Staff/index.vue")
  },
  {
    name: "Babies-uuid",
    path: "/Babies/:uuid()",
    meta: indexr4e_GheJ3SUx_jnEXMArazzbUSth8PY4ysiJ_4OTGwQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/index.vue")
  },
  {
    name: "MedicalVisits",
    path: "/MedicalVisits",
    meta: index_45KPmadwXULlADg5331NIpB29Hp1z5zPjgGZqC_45EWj40Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/index.vue")
  },
  {
    name: "Registrations",
    path: "/Registrations",
    meta: index5AlroRw1_ORV5PHaZLYzooDw1xReejerJ7EObbBFZ7cMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/index.vue")
  },
  {
    name: "Stats-registrations",
    path: "/Stats/registrations",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/registrations.vue")
  },
  {
    name: "Tablet-Babies",
    path: "/Tablet/Babies",
    meta: indexeIM32W77LghlJus5dDxGdDrkZEBGrZCdbpkVRpV83woMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/index.vue")
  },
  {
    name: "Tablet-Calendar-day",
    path: "/Tablet/Calendar/day",
    meta: dayHq47EavkhoeeLajrWChfCb9eNMBG9fXYDaHxN_45v3Zg0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/day.vue")
  },
  {
    name: "Tablet-Social",
    path: "/Tablet/Social",
    meta: index_fCN5RiXTlhSq38ZC76ANOtgYpV_P4bsI3Q9cOK1KI4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Social/index.vue")
  },
  {
    name: "MedicalVisits-create",
    path: "/MedicalVisits/create",
    meta: createhlcf7oNAkusclrshXEvzQB4I0Qffp3uc4p_45r4LiWuqcMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/create.vue")
  },
  {
    name: "Parents-uuid",
    path: "/Parents/:uuid()",
    meta: indexol9M_45P_45BP6Y49CXd_zcQw6kSB4vp_XlBqZqE3uhiixkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/index.vue")
  },
  {
    name: "Parents-uuid-mails",
    path: "/Parents/:uuid()/mails",
    meta: mails1MpEcDZBweqxAPyR11dOAugHohZZ68_457b8MMjQCyJagMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/mails.vue")
  },
  {
    name: "Registrations-uuid",
    path: "/Registrations/:uuid()",
    meta: _91uuid_93W1EhuxhZLMcWeeH4OI7ehC_45oe6yilv5lYmaYcSOKdVEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/[uuid].vue")
  },
  {
    name: "Registrations-create",
    path: "/Registrations/create",
    meta: created5UOQCw4hcDPRMKvNYUssvcaW_1D4_45KWSKv84KNw67EMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/create.vue")
  },
  {
    name: "Tablet-Babies-uuid",
    path: "/Tablet/Babies/:uuid()",
    meta: _91uuid_93ZDJBYBvwFcwb5WMuyI3IHESuNq7Ntzt9bWT1fB3uWw0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/[uuid].vue")
  },
  {
    name: "Tablet-Babies-create",
    path: "/Tablet/Babies/create",
    meta: createj_mLI0syNhhe7Pjc9gIvkA9aVkjuLc_45eYPkjW0RrDdgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/create.vue")
  },
  {
    name: "Tablet-Messages-id",
    path: "/Tablet/Messages/:id()",
    meta: _91id_93rBGPFGXFQ8_45nteeOy50FzCbKmzf6_451g76hDaPzhtAoQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/[id].vue")
  },
  {
    name: "Babies-uuid-finance",
    path: "/Babies/:uuid()/finance",
    meta: finance9aAfBy0dT468H3vG82y_Q0aIrtTskym7UYGhbvUF7GAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/finance.vue")
  },
  {
    name: "Babies-uuid-history",
    path: "/Babies/:uuid()/history",
    meta: historyTh_45rV2oHI_45TZ7VZk_452A6pSW7JZ0armXwgZXulkTgqOYMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/history.vue")
  },
  {
    name: "Babies-uuid-medical",
    path: "/Babies/:uuid()/medical",
    meta: medical6dbq37OAn19cPn0H_45rkVoK3EpHCefpQfQSqyxZ_45GtBAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/medical.vue")
  },
  {
    name: "Babies-uuid-parents",
    path: "/Babies/:uuid()/parents",
    meta: parentsYTlIZ7Jt4eDGVKfs3uterTxkFuNWe2y77Z8c_45itXls4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/parents.vue")
  },
  {
    name: "Communications-social",
    path: "/Communications/social",
    meta: socialpSwVZt4DhVglfLaG7zSWsOZKirahyZQ_OHbw0oWKkKEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/social.vue")
  },
  {
    name: "Parents-uuid-babies",
    path: "/Parents/:uuid()/babies",
    meta: babiesMtKVidf5N1nWRBoemGXyCZPhZzt__45jH7ra4IHNLoVtQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/babies.vue")
  },
  {
    name: "Reporting-attendances",
    path: "/Reporting/attendances",
    meta: attendancesI9X_45bNb5sEY2dqn7Oc71Nj2JD2GDubGV9vbyi6ONDYkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/attendances.vue")
  },
  {
    name: "Tablet-Activity",
    path: "/Tablet/Activity",
    meta: indexRyOlp_45tJxGW5PDqRYZMo4qzUBWEwRjZ9NVO_BQFtOooMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Activity/index.vue")
  },
  {
    name: "Tablet-Calendar-month",
    path: "/Tablet/Calendar/month",
    meta: month_TMx7_juZ6giLdi_455lvlKxlZe9_VeDxVfI9NbBrUeB4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/month.vue")
  },
  {
    name: "Tablet-Messages",
    path: "/Tablet/Messages",
    meta: indexVtUZBSxDR7x5N4wPR_45zC5Ijxa8hoPzfVgcg_T_FWIXkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/index.vue")
  },
  {
    name: "Babies-uuid-calendar",
    path: "/Babies/:uuid()/calendar",
    meta: calendar7KPTsVgzrIdeVU1Ag1zrw4HGaGXw15mvcbV3u1CKjIwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/calendar.vue")
  },
  {
    name: "Parents-uuid-account",
    path: "/Parents/:uuid()/account",
    meta: account_f0BzrKZbvtWnLCgQ9uXcxeliAnFSCFWCz1zzqnridwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/account.vue")
  },
  {
    name: "Settings-mailtemplates",
    path: "/Settings/mailtemplates",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/mailtemplates.vue")
  },
  {
    name: "Tablet-Messages-create",
    path: "/Tablet/Messages/create",
    meta: createsUuYzpoZAp6miw0efd3aL3rBLdga3m439w3s5ORxI6AMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/create.vue")
  },
  {
    name: "Babies-uuid-classroom",
    path: "/Babies/:uuid()/classroom",
    meta: classroom21iiEtQ6ppB5sskWyObvt41V607iCiEd0yH1CBHnhT8Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/classroom.vue")
  },
  {
    name: "Babies-uuid-documents",
    path: "/Babies/:uuid()/documents",
    meta: documentsUBkMf0M13iSgLB1X4_45AaTi_4513m_KCL1XHadARaTEzmAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/documents.vue")
  },
  {
    name: "Classrooms-uuid",
    path: "/Classrooms/:uuid()",
    meta: indexg0piucBNZvQ82lPoT4egHreWFm9g8hB2UhWXxOWIU2UMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/[uuid]/index.vue")
  },
  {
    name: "Parents-uuid-invoices",
    path: "/Parents/:uuid()/invoices",
    meta: invoicesg3wgC2QjkYVcsUKEksOwsQv225R_LG9SHdrLekQIytEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/invoices.vue")
  },
  {
    name: "Reporting-remunerations",
    path: "/Reporting/remunerations",
    meta: remunerations6IaEMdPgA5z_B8lM8Y9_3i2VRO_45JRO_45csrEu40BJy5EMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/remunerations.vue")
  },
  {
    name: "Babies-uuid-activities",
    path: "/Babies/:uuid()/activities",
    meta: activities3fnlwqt3ER24aZDhdSSPy32QSVEcPvXfbkspWas8g6sMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/activities.vue")
  },
  {
    name: "Communications-Sms",
    path: "/Communications/Sms",
    meta: index9ctyhJIMfH4iFjhTCYusYYdkQbWJ790ejd38AL5LmjQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/index.vue")
  },
  {
    name: "Organization-Staff",
    path: "/Organization/Staff",
    meta: indexrbCQBPL76h7gjiOf9uHgl0UVLqD3QjelM1zKG52HefMMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/index.vue")
  },
  {
    name: "Registrations-aes-create",
    path: "/Registrations/aes/create",
    meta: createShCC0QBUrSRxBGECUvr7ekSwhfBzu63xx_45r_xE4ktAgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/aes/create.vue")
  },
  {
    name: "Tablet-Attendances",
    path: "/Tablet/Attendances",
    meta: indexhAtbH_E5R4zoJlLKb2tf6DKZL2Vy65ne638e_450JFQdsMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Attendances/index.vue")
  },
  {
    name: "Communications-Sms-uuid",
    path: "/Communications/Sms/:uuid()",
    meta: _91uuid_93ldoPuJTLtGkz_GXPPLT5Z6tVnN876QO_45NMf_45oGJaZukMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/[uuid].vue")
  },
  {
    name: "Communications-Sms-create",
    path: "/Communications/Sms/create",
    meta: createyzJj1idBneNa8WN70SkJTKaWd5U_45Yt_IKIZ6euUA89gMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/create.vue")
  },
  {
    name: "MedicalVisits-uuid-edit",
    path: "/MedicalVisits/:uuid()/edit",
    meta: editCb0NufjXDh_455ACGQjzbHKfgsjTechKEp_h8SIPHZs9wMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/edit.vue")
  },
  {
    name: "Organization-Social",
    path: "/Organization/Social",
    meta: index5X0hdSKqLoiBpavbbvsKs0zYfgtPuNgwhniF9oROCrkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Social/index.vue")
  },
  {
    name: "Organization-Staff-uuid",
    path: "/Organization/Staff/:uuid()",
    meta: _91uuid_93MmHo6Y0qLYU7j0CnzWV3SBeBs4LGUDAcKd2o9E483FwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/[uuid].vue")
  },
  {
    name: "Organization-Staff-create",
    path: "/Organization/Staff/create",
    meta: createTNpLcyxSLmo47OT9ys6LoOUyEQPcLMOX09BLyF2AR1QMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/create.vue")
  },
  {
    name: "Reporting-fiscaldocuments",
    path: "/Reporting/fiscaldocuments",
    meta: fiscaldocumentsprSHgZ2of2s54lSpqa5JVZIXhpd_45B5gfgDQxGmL_ge8Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/fiscaldocuments.vue")
  },
  {
    name: "Babies-uuid-registration",
    path: "/Babies/:uuid()/registration",
    meta: registrationHoSR_45GUN9PUXs0_7MvTa6rBUcQSst1hCUQQthgCqxEwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/registration.vue")
  },
  {
    name: "MedicalVisits-uuid",
    path: "/MedicalVisits/:uuid()",
    meta: indexFWrZFZ1Eii0aOdUMqTlUcVgvCdR0DPZFIdsCt9G07TEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/index.vue")
  },
  {
    name: "Organization-Creches",
    path: "/Organization/Creches",
    meta: indexPWAgP_WhqlivgiPFpvZ27MkfkRAJOCehKdQuq8AC_45YwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/index.vue")
  },
  {
    name: "Organization-Invoices-coda",
    path: "/Organization/Invoices/coda",
    meta: codaAx6Fho3Xl_45s_45E9oHr_45oTfpcnOTvmv8JRAkdzO9_45oOZYMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/coda.vue")
  },
  {
    name: "Organization-Parents",
    path: "/Organization/Parents",
    meta: indexJmZhK6WQjeRKH60ZZsEzksr6iMKUsCeW_pnp78bJH9IMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/index.vue")
  },
  {
    name: "Communications-Emails",
    path: "/Communications/Emails",
    meta: index_IZnoWPXLPpJkpvcXhL3_BMjbE_45PA4uII3QMGHtLRMoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/index.vue")
  },
  {
    name: "Organization-Calendar",
    path: "/Organization/Calendar",
    meta: indexxTFfnPJrJX0h028vybZBPRSGHNkFLCVBb7Z8yHVF8wgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Calendar/index.vue")
  },
  {
    name: "Organization-Creches-uuid",
    path: "/Organization/Creches/:uuid()",
    meta: _91uuid_93VWZV2Y_Lexs8B6XZNb2JaTRzZDXBvneAMVHiZvaJvXUMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/[uuid].vue")
  },
  {
    name: "Organization-Invoices",
    path: "/Organization/Invoices",
    meta: indexSW_Y9F_45keW05nAAl1xdWYUuIHwe2rsG_nVW0l88ISc0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/index.vue")
  },
  {
    name: "Organization-Messages",
    path: "/Organization/Messages",
    meta: indexfjPA6kYSBkXgYGzLTYFGpesWE6YlG_AYNhchpvNd_45xEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/index.vue")
  },
  {
    name: "Organization-Parents-uuid",
    path: "/Organization/Parents/:uuid()",
    meta: _91uuid_93t9C0lr1h63mhe7mj6HcDG8Oy7bkd7HD27LFlXEBloeMMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/[uuid].vue")
  },
  {
    name: "Organization-Settings",
    path: "/Organization/Settings",
    meta: index1uDnRyEg10XjQndqeYXQI1xLThye3uk7OkReEO4ddVEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Settings/index.vue")
  },
  {
    name: "Communications-Emails-uuid",
    path: "/Communications/Emails/:uuid()",
    meta: _91uuid_93nvYz2cz1TGfdEpKNw61E8HITs_45Pvz_3adD_b71iBLHEMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/[uuid].vue")
  },
  {
    name: "Communications-Emails-create",
    path: "/Communications/Emails/create",
    meta: createe10GqoWkIL9WhOJJ0brn62eaCRT4IrbQKw5pZmtbzsoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/create.vue")
  },
  {
    name: "Organization-Invoices-uuid",
    path: "/Organization/Invoices/:uuid()",
    meta: _91uuid_93HYNuSIBPFJtCvx_45B22bs9yYA9WiI1gtM0oFF4WHeTjAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/[uuid].vue")
  },
  {
    name: "Organization-Invoices-create",
    path: "/Organization/Invoices/create",
    meta: createCjo1eSYWz9FvToZgRjSMq55WD_45BL8sBwLpkMiZszhesMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/create.vue")
  },
  {
    name: "Organization-Messages-uuid",
    path: "/Organization/Messages/:uuid()",
    meta: _91uuid_93PpfDTcuSn_45foy_4584IbjsylvU_4531iOexbiZs9p3CY_45BUMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/[uuid].vue")
  },
  {
    name: "Organization-Messages-create",
    path: "/Organization/Messages/create",
    meta: createtcBxMRssSGe6MztLIo8t4CUV_45nXYThrmSHNubdA_2mQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/create.vue")
  },
  {
    name: "Organization-Reports-billing",
    path: "/Organization/Reports/billing",
    meta: billingeBUpqifs4f7Fb_45xXLTcvBG0yq7rJvmiYcbUAomCzEbIMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/billing.vue")
  },
  {
    name: "Organization-Reports-aes",
    path: "/Organization/Reports/aes",
    meta: indexBPp6ILMyBYH4lVK85CYfe7ddd10GpDWha1BuS1glIEgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/aes/index.vue")
  },
  {
    name: "Organization-Reports-sae",
    path: "/Organization/Reports/sae",
    meta: indexbqNSysgeE2FcrCfLLmNZl5ADxWGzyl9Lhk_45qLkS7dL0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/index.vue")
  },
  {
    name: "Organization-Availability",
    path: "/Organization/Availability",
    meta: indexjJ7gDkNpZRwMaFTXxRMkSkvr5yPTbZJMM9PmOG2ewkoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Availability/index.vue")
  },
  {
    name: "Organization-Registrations",
    path: "/Organization/Registrations",
    meta: indexreqp_453q26cl6mxg3xUeGzkXjkHilBhm3fkyJ_2KhPNMMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/index.vue")
  },
  {
    name: "Organization-Reports-attendances",
    path: "/Organization/Reports/attendances",
    meta: attendancesCmrnq8ZO6gAGg4qshCQIladZhEJurXYGH3Kf_auYqqsMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/attendances.vue")
  },
  {
    name: "Organization-Reports-sae-pro-one",
    path: "/Organization/Reports/sae/pro-one",
    meta: pro_45one7il_45Z2ckokenu1wL1Ic2ATx4oid5s4TgdyeK8ZukmIsMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/pro-one.vue")
  },
  {
    name: "Organization-Registrations-uuid",
    path: "/Organization/Registrations/:uuid()",
    meta: _91uuid_93tgysuK_45jTnPIu8I647_45ykweRt_rA_45Z5343x1U2KPlKMMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/[uuid].vue")
  },
  {
    name: "Organization-Registrations-create",
    path: "/Organization/Registrations/create",
    meta: createTpXf3Ye8c1s4TTdI8S_45Bs1mwivI7ogVSNviOBM4FPvgMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/create.vue")
  },
  {
    name: "Organization-Reports-remunerations",
    path: "/Organization/Reports/remunerations",
    meta: remunerations_6Cwn5s5RPYHK9zvEgjZr_45jTF_Rpvid9h8A0MCPMlEkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/remunerations.vue")
  }
]