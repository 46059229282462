import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtPlugin(() => {
    window.Pusher = Pusher
    const config = useRuntimeConfig()

    const authStore = useAuthStore()

    const echo = new Echo({
        broadcaster: 'pusher',
        key: config.public.VITE_PUSHER_APP_KEY,
        wsHost: config.public.VITE_PUSHER_HOST,
        wsPort: config.public.VITE_PUSHER_PORT,
        wssPort: config.public.VITE_PUSHER_PORT,
        cluster: config.public.VITE_PUSHER_APP_CLUSTER,
        forceTLS: false,
        encrypted: false,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authModule: true,
        authEndpoint: 'https://abcreche.com/api/v1/broadcasting/auth',
        auth: {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            }
        }
    })

    return {
        provide: {
            echo
        }
    }
})
